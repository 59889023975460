import Actions from "modules/actions/sample-storage-laboratory-actions";

export default {
  name: "FacilityAddress",
  components: {},
  props: {
    facility: {
      type: Object,
      required: true,
    },
  },
  computed: {
    __Facility: {
      get() {
        return this.facility;
      },
      set(value) {
        this.$emit("update:facility", value);
      },
    },
  },
  data() {
    return {
      actions: new Actions(),
      promptData: null,
      promptVisibility: false,
      promptMessage: "",
      isLoading: false,
      columns: [
        {
          text: "Address Type",
          align: "left",
          sortable: false,
        },
        {
          text: "Default",
          align: "left",
          sortable: false,
        },
        {
          text: "Address Line 1",
          align: "left",
          sortable: false,
        },
        {
          text: "Address Line 2",
          align: "left",
          sortable: false,
        },
        {
          text: "City",
          align: "left",
          sortable: false,
        },
        {
          text: "State",
          align: "left",
          sortable: false,
        },
        {
          text: "Postal Code",
          align: "left",
          sortable: false,
        },
        {
          text: "Country",
          align: "left",
          sortable: false,
        },
        {
          text: "Main Phone No",
          align: "left",
          sortable: false,
        },
        {
          text: "Main Fax No",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getDefaultStatus(id) {
      const defaultId = this.facility.defaultAddress?.id;
      return defaultId == id ? "Yes" : "No";
    },
    editAddress(id) {
      this.$emit("editAddress", id);
    },
    async deleteAddress(addressId) {
      try {
        await this.actions.updateSampleStorageLaboratory({
          storageLabId: {
            type: "UUID!",
            value: this.__Facility.id,
            unincludeToFields: true,
          },
          queries: {
            removeAddress: {
              addressId: {
                type: "UUID!",
                value: addressId,
              },
            },
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Facility address deleted.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        this.getFacilityAddresses();
      }
    },
    setPromptModalVisibility({ action, id, message }) {
      this.promptVisibility = true;
      this.promptMessage = message;
      this.promptData = {
        action,
        id,
      };
    },
    async setPromptAction(data) {
      const { action, id } = data;
      await this[action](id);
    },
    async getFacilityAddresses() {
      try {
        this.isLoading = true;

        const result = await this.actions.getSampleStorageLaboratory({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.__Facility.id,
              },
            },
          },
          limitData: [
            this.buildSubQuery("defaultAddress", [
              "line1",
              "line2",
              "city",
              "country",
              "postalCode",
              "stateProvince",
              this.buildSubQuery("addressType", ["label"]),
              this.buildSubQuery("mainPhone", [
                "phone",
                "areaCode",
              ]),
              this.buildSubQuery("mainFax", [
                "phone",
                "areaCode",
              ]),
            ]),
            this.buildSubQuery("addresses", [
              "line1",
              "line2",
              "city",
              "country",
              "postalCode",
              "stateProvince",
              this.buildSubQuery("addressType", ["label"]),
              this.buildSubQuery("mainPhone", ["phone", "areaCode"]),
              this.buildSubQuery("mainFax", ["phone", "areaCode"]),
            ]),
          ],
        });

        if (result) {
          this.__Facility.defaultAddress = result.defaultAddress;
          this.__Facility.addresses = this.setAddressObject(result.addresses);
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    setAddressObject(data) {
      Object.keys(data).forEach((index) => {
        let address = {};
        Object.keys(data[index]).forEach((inner) => {
          if (!(data[index][inner] instanceof Object)) {
            address[inner] = data[index][inner];
            delete data[index][inner];
          }
        });
        data[index]["address"] = address;
      });

      data.map((address) => {
        address.addressType = address.addressType || { label: "" };
        address.mainPhone = address.mainPhone || { phone: "" };
        address.mainFax = address.mainFax || { phone: "" };
        return address;
      });

      return data;
    },
    renameProperty(obj, newKey, oldKey) {
      Object.defineProperty(
        obj,
        newKey,
        Object.getOwnPropertyDescriptor(obj, oldKey)
      );
      delete obj[oldKey];
    },
  },
  async created() {
    await this.getFacilityAddresses();
  },
};
