var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('v-container',{staticClass:"white"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"facility_contact_table","headers":_vm.columns,"items":_vm.__Facility.contactInfo,"items-per-page":10,"footer-props":{
          'items-per-page-options': [5, 10, 15, 20],
          showFirstLastPage: true,
        },"loading":_vm.isLoading,"no-data-text":"Contact informations not found."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(((item.firstName) + " " + (item.lastName)) || "--"))]),_c('td',[_vm._v(_vm._s(_vm.getDefaultStatus(item.id)))]),_c('td',[_vm._v(_vm._s(item.nameTitle || "--"))]),_c('td',[_vm._v(_vm._s(item.mainPhone.phone || "--"))]),_c('td',[_vm._v(_vm._s(item.mainFax.phone || "--"))]),_c('td',[_vm._v(_vm._s(item.email.email || "--"))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("parseDateFromString")(item.created,"MM/DD/YYYY hh:mm A"))+" ")]),_c('td',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" More ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editContact(item.id)}}},[_c('v-list-item-title',[_vm._v("View/Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.setPromptModalVisibility({
                        action: 'deleteContact',
                        id: item.id,
                        message: 'Delete this facility contact?',
                      })}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)])]}}])})],1)],1),_c('v-prompt-modal',{ref:"promptModal",attrs:{"promptVisibility":_vm.promptVisibility,"message":_vm.promptMessage,"emitData":_vm.promptData},on:{"update:promptVisibility":function($event){_vm.promptVisibility=$event},"update:prompt-visibility":function($event){_vm.promptVisibility=$event},"doOk":_vm.setPromptAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }