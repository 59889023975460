import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";
import Actions from "modules/actions/sample-storage-laboratory-actions";

export default {
  name: "FacilityAddressModal",
  props: {
    address: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
    },
  },
  computed: {
    getType() {
      return this.type.toUpperCase();
    },
  },
  data() {
    return {
      addressCopy: {
        isDefault: false,
        address: {
          id: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          stateProvince: "",
          country: "",
        },
        addressType: {
          id: null,
          label: "",
        },
        mainPhone: {
          id: null,
          phone: "",
          label: "",
        },
        mainFax: {
          id: null,
          phone: "",
          label: "",
        },
      },
      actions: new Actions(),
      isLoading: false,
      isSelectedDefault: false,
      rules: {
        required: [
          (val) => {
            if (
              val === null ||
              val === undefined ||
              val.length === 0 ||
              val === ""
            ) {
              return "This field is required";
            }
            return true;
          },
        ],
      },
    };
  },
  methods: {
    async createAddress(paramAddress) {
      const { address, mainFax, mainPhone } = paramAddress;
      let params = {
        line1: {
          type: "String!",
          value: address.line1,
        },
        line2: {
          type: "String",
          value: address.line2,
        },
        city: {
          type: "String",
          value: address.city,
        },
        postalCode: {
          type: "String",
          value: address.postalCode,
        },
        country: {
          type: "String",
          value: address.country,
        },
        stateProvince: {
          type: "String",
          value: address.stateProvince,
        },
      };

      const createdPhone = await this.createPhone(mainPhone);
      const createdFax = await this.createPhone(mainFax);

      return await this.actions.createAddress({
        params,
        queries: {
          setAddressType: {
            addressTypeId: {
              type: "UUID!",
              value: address.addressType,
            },
          },
        },
        collectionQueries: [
          {
            setMainPhone: {
              phoneId: {
                type: "UUID!",
                value: createdPhone.id,
              },
            },
            setMainFax: {
              phoneId: {
                type: "UUID!",
                value: createdFax.id,
              },
            },
          },
        ],
      });
    },
    async createPhone(phone) {
      let param = {
        phone: {
          type: "String!",
          value: this.sanitizePhoneString(phone.phone),
          unincludeToFields: true,
        },
        areaCode: {
          type: "String!",
          value: this.sanitizePhoneString(phone.areaCode),
          unincludeToFields: true,
        },
        collectionQueries: [],
        queries: {},
      };
      return await this.actions.createPhone(param);
    },
    async validateForm() {
      try {
        let validateForms = [
          await this.$refs.facilityAddress.$refs.form.validateAsync(),
          await this.$refs.mainPhone.$refs.form.validateAsync(),
          await this.$refs.mainFax.$refs.form.validateAsync(),
        ];
        return validateForms.filter((bool) => !bool).length === 0;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async saveAddress() {
      this.isLoading = true;
      const validate = await this.validateForm();
      const storageLabId = `${this.$route.params.id}`;
      if (!validate) {
        this.showNotifyMessage({
          message: `Please provide all necessary fields.`,
          type: "danger",
        });
        this.isLoading = false;
        return;
      }
      if (!this.addressCopy.address.id) {
        const createdAddress = await this.createAddress(this.addressCopy);

        let queries = {
          addAddress: {
            addressId: {
              type: "UUID!",
              value: createdAddress.id,
            },
          },
        };
        if (this.addressCopy.isDefault) {
          queries["setDefaultAddress"] = {
            addressId: {
              type: "UUID!",
              value: createdAddress.id,
            },
          };
        }
        if (this.address.isDefault) {
          queries["setDefaultAddress"] = {
            addressId: {
              type: "UUID!",
              value: createdAddress.id,
            },
          };
        }
        await this.actions.updateSampleStorageLaboratory({
          storageLabId: {
            type: "UUID!",
            value: storageLabId,
            unincludeToFields: true,
          },
          queries,
        });
      } else {
        await this.processPhoneQuery(this.addressCopy);
        const { address } = this.addressCopy;
        const addressTypeId = address.addressType.id ?? address.addressType;

        let queries = {
          updateAddress: {
            addressId: {
              type: "UUID!",
              value: address.id,
              unincludeToFields: true,
            },
            line1: {
              type: "String!",
              value: address.line1,
            },
            line2: {
              type: "String",
              value: address.line2,
            },
            city: {
              type: "String",
              value: address.city,
            },
            postalCode: {
              type: "String",
              value: address.postalCode,
            },
            country: {
              type: "String",
              value: address.country,
            },
            stateProvince: {
              type: "String",
              value: address.stateProvince,
            },
            addressTypeId: {
              type: "UUID!",
              value: addressTypeId,
            },
          },
        };

        if (this.addressCopy.isDefault && !this.isSelectedDefault) {
          queries["setDefaultAddress"] = {
            addressId: {
              type: "UUID!",
              value: address.id,
            },
          };
        } else if (!this.addressCopy.isDefault && this.isSelectedDefault) {
          queries["unsetDefaultAddress"] = {
            unsetDefaultAddress: {
              type: "UUID!",
              value: address.id,
            },
          };
        }

        await this.actions.updateSampleStorageLaboratory({
          storageLabId: {
            type: "UUID!",
            value: storageLabId,
            unincludeToFields: true,
          },
          queries,
        });
      }

      this.showNotifyMessage({
        message: "Successfully saved ",
        type: "success",
      });
      this.isLoading = false;
      this.$emit("closeModal");
    },
    async processPhoneQuery(paramAddress) {
      let createdPhone = null;
      let createdFax = null;
      let collectionQueries = [];

      const { mainFax, mainPhone } = paramAddress;

      if (!mainFax.id) {
        createdFax = await this.createPhone(mainPhone);
      }
      if (!mainPhone.id) {
        createdPhone = await this.createPhone(mainFax);
      }

      if (createdPhone) {
        collectionQueries.push({
          setMainPhone: {
            phoneId: {
              type: "UUID!",
              value: createdPhone.id,
            },
          },
        });
      } else if (mainPhone.id) {
        collectionQueries.push({
          updatePhone: {
            phoneId: {
              type: "UUID!",
              value: mainPhone.id,
            },
            phone: {
              type: "String",
              value: this.sanitizePhoneString(mainPhone.phone),
            },
            areaCode: {
              type: "String",
              value: this.sanitizePhoneString(mainPhone.areaCode),
            },
          },
        });
      }

      if (createdFax) {
        collectionQueries.push({
          setMainFax: {
            phoneId: {
              type: "UUID!",
              value: createdFax.id,
            },
          },
        });
      } else if (mainFax.id) {
        collectionQueries.push({
          updateFax: {
            phoneId: {
              type: "UUID!",
              value: mainFax.id,
            },
            phone: {
              type: "String",
              value: this.sanitizePhoneString(mainFax.phone),
            },
            areaCode: {
              type: "String",
              value: this.sanitizePhoneString(mainFax.areaCode),
            },
          },
        });
      }
      await this.actions.updateAddressDetails({
        variables: {
          addressId: {
            type: "UUID!",
            value: this.address.address.id,
            unincludeToFields: true,
          },
        },
        collectionQueries,
      });
    },

    onCancel() {
      this.compareAddressObject();

      if (this.hasChanges) {
        this.$emit("confirmModal");
      } else {
        this.$emit("closeModal");
      }
    },
    compareAddressObject() {
      const objectKeys = Object.keys(this.addressCopy);
      objectKeys.forEach(item => {
        if (item === "mainPhone" || item === "mainFax") {
          this.addressCopy[item].phone = this.sanitizePhoneString(this.addressCopy[item].phone);
          this.addressCopy[item].areaCode = this.sanitizePhoneString(this.addressCopy[item].areaCode);
          const resultOfPhone = isEqualWith(
            this.addressCopy[item],
            this.address[item],
            (value, otherValue) => {
              if (value.areaCode === undefined || otherValue.areaCode === undefined) return true;
              return (
                value.phone === otherValue.phone &&
                value.areaCode === otherValue.areaCode
              );
            }
          );
          if (!resultOfPhone) {
            this.hasChanges = true;
            return;
          }
        } else {
          if (!isEqual(this.addressCopy[item], this.address[item])) {
            this.hasChanges = true;
            return;
          }
        }
      });
    },
    async getAddressTypes() {
      try {
        if (!this.addressTypes.length) {
          this.addressTypes = [
            { label: "Fetching address types...", disabled: true },
          ];

          const result = await this.actions.getAllAddressTypes({
            filter: {
              forBilling: true,
            },
          });

          if (result) {
            this.addressTypes = result.map((item) => {
              return {
                id: item.id,
                label: item.label,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Problem has occurred while saving data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Problem has occurred while saving data.",
        });
      }
    },
  },
  created() {
    this.addressCopy = JSON.parse(JSON.stringify(this.address));
    this.isSelectedDefault = this.address.isDefault;
  },
};
