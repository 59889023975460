import Actions from "modules/actions/sample-storage-laboratory-actions";
import GeneralInformation from "../../shared/GeneralInformation";
import FacilityAddress from "./sections/FacilityAddress";
import ContactInformation from "./sections/ContactInformation";
import FacilityAddressModal from "./modals/FacilityAddressModal";
import ContactInfoModal from "./modals/ContactInfoModal";

export default {
  name: "EditFacility",
  components: {
    GeneralInformation,
    FacilityAddress,
    ContactInformation,
    FacilityAddressModal,
    ContactInfoModal,
  },
  data() {
    return {
      actions: new Actions(),
      type: "view",
      modalType: "add",
      panel: [0, 1, 2, 3],
      facility: null,
      dialog: false,
      addressDialog: false,
      contactDialog: false,
      addressModel: null,
      contactModel: null,
      createNpiNumber: null,
      generalInfoCopy: null,
      renderComponent: true,
      dialogMsg: "Leaving this modal will not save the data you enter.",
    };
  },
  computed: {
    onEditGeneralInfo() {
      return this.addressDialog == false && this.contactDialog == false;
    },
  },
  methods: {
    async processNpi() {
      if (this.facility.npi && !this.facility.npi.id) {
        let param = {};
        param = {
          npiNumber: {
            type: "String!",
            value: this.facility.npi.npiNumber,
            unincludeToFields: true,
          },
        };
        this.createNpiNumber = await this.actions.createNpi({
          params: param,
        });
      }
    },
    async confirmSaveGeneralInfoAction() {
      try {
        let queries = [];
        const storageLabId = `${this.$route.params.id}`;
        await this.processNpi();
        queries["updateFacilityId"] = {
          facilityId: {
            type: "String",
            value: this.facility.facilityId,
          },
        };

        queries["updateLabel"] = {
          label: {
            type: "String!",
            value: this.facility.label,
          },
        };

        queries["updateTaxId"] = {
          taxId: {
            type: "String",
            value: this.facility.taxId,
          },
        };
        if (this.createNpiNumber) {
          queries["setNpi"] = {
            npiId: {
              type: "UUID!",
              value: this.createNpiNumber.id,
            },
          };
        } else if (this.facility.npi.id) {
          queries["updateNpi"] = {
            npiNumber: {
              type: "String",
              value: this.facility.npi.npiNumber,
            },
          };
        }

        if (this.facility.sampleTestOrderCode) {
          queries["updateSampleTestOrderCode"] = {
            sampleTestOrderCode: {
              type: "String!",
              value: this.facility.sampleTestOrderCode,
            },
          };
        }
        queries["updateFacilityType"] = {
          facilityType: {
            type: "FacilityType!",
            value: this.facility.facilityType,
          },
        };
        queries["updateTaxonomyCode"] = {
          taxonomyCode: {
            type: "String",
            value: this.facility.taxonomyCode,
          },
        };

        const collectionQueries = [{
          updateValidityDates: {
            effectivityDate: {
              type: "String",
              value: this.facility.effectivityDate
                ? this.$options.filters.getTimeDate(
                    this.facility.effectivityDate,
                    "YYYY-MM-DD HH:mm:ss",
                    false,
                    null,
                    this.getTimezoneOffset({
                      date: this.facility.effectivityDate,
                    })
                  ).utc
                : "",
              unincludeToFields: true,
            },
            terminationDate: {
              type: "String",
              value: this.facility.terminationDate
                ? this.$options.filters.getTimeDate(
                    this.facility.terminationDate,
                    "YYYY-MM-DD HH:mm:ss",
                    false,
                    null,
                    {
                      days: 1,
                      ...this.getTimezoneOffset({
                        date: this.facility.terminationDate,
                        minusSeconds: 1,
                      }),
                    }
                  ).utc
                : "",
              unincludeToFields: true,
            },
            mutationReturn: ["success", "errors"],
          },
        }];

        await this.actions.updateSampleStorageLaboratory({
          storageLabId: {
            type: "UUID!",
            value: storageLabId,
            unincludeToFields: true,
          },
          queries,
          collectionQueries,
        });

        this.showNotifyMessage({
          message: "Successfully saved ",
          type: "success",
        });
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
      this.dialog = false;
    },
    renameProperty(obj, newKey, oldKey) {
      Object.defineProperty(
        obj,
        newKey,
        Object.getOwnPropertyDescriptor(obj, oldKey)
      );
      delete obj[oldKey];
    },
    setAddressObject() {
      Object.keys(this.facility.addresses).forEach((index) => {
        let address = {};
        Object.keys(this.facility.addresses[index]).forEach((inner) => {
          if (!(this.facility.addresses[index][inner] instanceof Object)) {
            address[inner] = this.facility.addresses[index][inner];
            delete this.facility.addresses[index][inner];
          }
        });
        this.facility.addresses[index]["address"] = address;
      });

      this.facility.addresses.map((address) => {
        address.addressType = address.addressType || { label: "" };
        address.mainPhone = address.mainPhone || { phone: "" };
        address.mainFax = address.mainFax || { phone: "" };
        return address;
      });

      this.facility.contactInfo.map((contact) => {
        contact.email = contact.email || { email: "" };
        contact.mainPhone = contact.mainPhone || { phone: "" };
        contact.mainFax = contact.mainFax || { phone: "" };
        return contact;
      });

      this.facility.npi = this.facility.npi || {
        npiNumber: "",
      };
    },

    async addAddress() {
      await this.actions.getAllAddressTypes({
        filter: {
          forBilling: true,
        },
      });
      this.addressModel = {
        isDefault: true,
        addressType: {
          id: null,
          label: "",
        },
        address: {
          line1: "",
          line2: "",
          city: "",
          country: "United States",
          postalCode: "",
          stateProvince: "",
        },
        mainPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        mainFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        email: {
          email: "",
        },
      };
      this.modalType = "add";
      this.addressDialog = true;
    },
    async editAddress(id) {
      await this.actions.getAllAddressTypes({
        filter: {
          forBilling: true,
        },
      });
      this.addressModel = {
        ...this.facility.addresses.find((address) => address.address.id == id),
      };
      const defaultId = this.facility.defaultAddress?.id;
      this.addressModel["isDefault"] =
        defaultId == this.addressModel.address.id ? true : false;
      this.addressModel.address["addressType"] =
        this.addressModel["addressType"];
      delete this.addressModel["addressType"];
      this.modalType = "edit";
      this.addressDialog = true;
    },

    addContact() {
      this.contactModel = {
        isDefault: true,
        firstName: "",
        lastName: "",
        nameTitle: "",
        mainPhone: {
          uuid: null,
          phone: "",
          label: "",
        },
        mainFax: {
          uuid: null,
          phone: "",
          label: "",
        },
        email: {
          email: "",
        },
      };
      this.modalType = "add";
      this.contactDialog = true;
    },
    editContact(id) {
      this.contactModel = this.facility.contactInfo.find(
        (contact) => contact.id == id
      );
      const defaultId = this.facility.defaultContactInformation?.id;
      this.contactModel["isDefault"] =
        defaultId == this.contactModel.id ? true : false;
      this.modalType = "edit";
      this.contactDialog = true;
    },
    confirmCloseAction() {
      this.dialog = false;
      if (!this.contactDialog && !this.addressDialog && this.generalInfoCopy) {
        this.renderComponent = false;
        this.facility = JSON.parse(JSON.stringify(this.generalInfoCopy));
        this.$nextTick(() => {
          this.renderComponent = true;
        });
        return;
      }
      this.contactDialog = false;
      this.addressDialog = false;
    },
    showConfirmSaveModal() {
      this.dialogMsg = "Are you sure you want to save the updates?";
      this.dialog = true;
    },
    showConfirmCloseModal() {
      this.dialogMsg = "Leaving this modal will not save the data you enter.";
      this.dialog = true;
    },
    showConfirmCloseGenInfo(facilityCopy) {
      this.dialogMsg = "Are you sure you want to leave the edit mode?";
      this.dialog = true;
      this.generalInfoCopy = facilityCopy;
    },
    async closeAddressModal() {
      this.addressDialog = false;
      await this.getSampleStorage();
    },
    async closeContactModal() {
      this.contactDialog = false;
      await this.getSampleStorage();
    },
    async getSampleStorage() {
      try {
        if (this.$route.params.id) {
          const result = await this.actions.getSampleStorageLaboratory({
            variables: {
              id: this.$route.params.id,
            },
          });

          result.effectivityDate = this.$options.filters.getTimeDate(
            result.effectivityDate,
            "MM/DD/YYYY"
          ).zone;
          result.terminationDate = this.$options.filters.getTimeDate(
            result.terminationDate,
            "MM/DD/YYYY"
          ).zone;

          this.facility = JSON.parse(JSON.stringify(result));
          this.renameProperty(
            this.facility,
            "contactInfo",
            "contactInformations"
          );
          this.facility.contactInfo.forEach((index) => {
            this.renameProperty(index, "email", "contactEmail");
          });
          this.setAddressObject();
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
  },

  async created() {
    await this.getSampleStorage();
  },
};
