import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";
import Actions from "modules/actions/sample-storage-laboratory-actions";

export default {
  name: "ContactInfoModal",
  props: {
    contactInfo: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
    },
  },
  computed: {
    getType() {
      return this.type.toUpperCase();
    },
  },
  data() {
    return {
      isLoading: false,
      actions: new Actions(),
      contactInformation: {
        isDefault: true,
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        nameTitle: "",
        mainPhone: {
          id: null,
          phone: "",
          label: "",
        },
        mainFax: {
          id: null,
          phone: "",
          label: "",
        },
        contactEmail: {
          id: null,
          email: "",
          emailTypeId: null,
        },
      },
      hasChanges: false,
      isSelectedDefault: false,
      createdMainPhone: null,
      createdMainFax: null,
      createdEmail: null,
      rules: {
        required: (val) => {
          if (typeof val === "object")
            return (val.id && val.id !== null) || "This field is required";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateEmailType: (val, email) => {
          return email.length > 0
            ? (val !== null && val.length > 0) || "This field is required."
            : true;
        },
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
      },
    };
  },
  methods: {
    async createPhone(phone) {
      let param = {
        phone: {
          type: "String!",
          value: this.sanitizePhoneString(phone.phone),
          unincludeToFields: true,
        },
        areaCode: {
          type: "String!",
          value: this.sanitizePhoneString(phone.areaCode),
          unincludeToFields: true,
        },
        collectionQueries: [],
        queries: {},
      };
      return await this.actions.createPhone(param);
    },
    async createEmail(email) {
      return await this.actions.createEmail({
        variables: {
          email: {
            type: "String!",
            value: email,
          },
        },
        queries: {},
      });
    },
    async processDataCreation() {
      if (!this.contactInformation.mainPhone.id) {
        this.createdMainPhone = await this.createPhone(
          this.contactInformation.mainPhone
        );
      }
      if (!this.contactInformation.mainFax.id) {
        this.createdMainFax = await this.createPhone(
          this.contactInformation.mainFax
        );
      }
      if (!this.contactInformation.email.id) {
        this.createdEmail = await this.createEmail(
          this.contactInformation.email.email
        );
      }
    },
    async validateForm() {
      let validateForms = [
        await this.$refs.form.validateAsync(),
        await this.$refs.mainPhone.$refs.form.validateAsync(),
        await this.$refs.mainFax.$refs.form.validateAsync(),
      ];
      return validateForms.filter((bool) => !bool).length === 0;
    },
    async onSave() {
      try {
        this.isLoading = true;
        const validate = await this.validateForm();
        if (!validate) {
          this.showNotifyMessage({
            message: `Please provide all necessary fields.`,
            type: "danger",
          });
          this.isLoading = false;
          return;
        }
        await this.processDataCreation();
        const storageLabId = `${this.$route.params.id}`;
        let queries = [];
        let collectionQueries = [];
        if (!this.contactInformation.id) {
          let ciParam = {
            variables: {
              firstName: {
                type: "String!",
                value: this.contactInformation.firstName,
              },
              lastName: {
                type: "String!",
                value: this.contactInformation.lastName,
              },
              nameTitle: {
                type: "String!",
                value: this.contactInformation.nameTitle,
              },
            },
            queries: {
              setEmail: {
                emailId: {
                  type: "UUID!",
                  value: this.createdEmail?.id,
                },
              },
            },
            collectionQueries: [
              {
                setMainPhone: {
                  phoneId: {
                    type: "UUID!",
                    value: this.createdMainPhone?.id,
                  },
                },
                setMainFax: {
                  phoneId: {
                    type: "UUID!",
                    value: this.createdMainFax?.id,
                  },
                },
              },
            ],
          };

          if (this.contactInformation.middleName) {
            ciParam.variables["middleName"] = {
              type: "String",
              value: this.contactInformation.middleName,
            };
          }
          const createContactInformation =
            await this.actions.createContactInformation(ciParam);
          collectionQueries.push({
            addContactInformation: {
              contactInformationId: {
                type: "UUID!",
                value: createContactInformation.id,
              },
            },
          });
          if (this.contactInformation.isDefault) {
            queries["setDefaultContactInformation"] = {
              contactInformationId: {
                type: "UUID!",
                value: createContactInformation.id,
              },
            };
          }
          await this.actions.updateSampleStorageLaboratory({
            storageLabId: {
              type: "UUID!",
              value: storageLabId,
              unincludeToFields: true,
            },
            queries,
            collectionQueries,
          });
        } else {
          let ciParam = {
            variables: {
              contactInformationId: {
                type: "UUID!",
                value: this.contactInformation.id,
                unincludeToFields: true,
              },
            },
            queries: {
              updateName: {
                firstName: {
                  type: "String",
                  value: this.contactInformation.firstName,
                },
                middleName: {
                  type: "String",
                  value: this.contactInformation.middleName,
                },
                lastName: {
                  type: "String",
                  value: this.contactInformation.lastName,
                },
              },
              setNameTitle: {
                nameTitle: {
                  type: "String!",
                  value: this.contactInformation.nameTitle,
                },
              },
            },
            collectionQueries: [],
          };
          if (this.createdMainPhone) {
            ciParam.collectionQueries.push({
              setMainPhone: {
                phoneId: {
                  type: "UUID!",
                  value: this.createdMainPhone.id,
                },
              },
            });
          } else if (this.contactInformation.mainPhone.id) {
            ciParam.collectionQueries.push({
              updatePhone: {
                phoneId: {
                  type: "UUID!",
                  value: this.contactInformation.mainPhone.id,
                },
                phone: {
                  type: "String",
                  value: this.sanitizePhoneString(
                    this.contactInformation.mainPhone.phone
                  ),
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(
                    this.contactInformation.mainPhone.areaCode
                  ),
                },
              },
            });
          }

          if (this.createdMainPhone) {
            ciParam.collectionQueries.push({
              setMainFax: {
                phoneId: {
                  type: "UUID!",
                  value: this.createdMainFax.id,
                },
              },
            });
          } else if (this.contactInformation.mainFax.id) {
            ciParam.collectionQueries.push({
              updateFax: {
                phoneId: {
                  type: "UUID!",
                  value: this.contactInformation.mainFax.id,
                },
                phone: {
                  type: "String",
                  value: this.sanitizePhoneString(
                    this.contactInformation.mainFax.phone
                  ),
                },
                areaCode: {
                  type: "String",
                  value: this.sanitizePhoneString(
                    this.contactInformation.mainFax.areaCode
                  ),
                },
              },
            });
          }

          if (this.createdEmail) {
            ciParam.collectionQueries.push({
              setEmail: {
                emailId: {
                  type: "UUID!",
                  value: this.createdEmail.id,
                },
              },
            });
          } else if (this.contactInformation.email.id) {
            ciParam.collectionQueries.push({
              updateEmail: {
                emailId: {
                  type: "UUID!",
                  value: this.contactInformation.email.id,
                },
                email: {
                  type: "String!",
                  value: this.contactInformation.email.email,
                },
              },
            });
          }

          await this.actions.updateContactInformation(ciParam);

          if (this.contactInformation.isDefault && !this.isSelectedDefault) {
            queries["setDefaultContactInformation"] = {
              contactInformationId: {
                type: "UUID!",
                value: this.contactInformation.id,
              },
            };
          } else if (
            !this.contactInformation.isDefault &&
            this.isSelectedDefault
          ) {
            queries["unsetDefaultContactInformation"] = {
              contactInformationId: {
                type: "UUID!",
                value: this.contactInformation.id,
              },
            };
          }
          await this.actions.updateSampleStorageLaboratory({
            storageLabId: {
              type: "UUID!",
              value: storageLabId,
              unincludeToFields: true,
            },
            queries,
          });
        }
        this.showNotifyMessage({
          message: "Successfully saved ",
          type: "success",
        });
        this.$emit("closeModal");
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
      this.isLoading = false;
    },
    onCancel() {
      this.compareContactObject();
      if (this.hasChanges) {
        this.$emit("confirmModal");
      } else {
        this.$emit("closeModal");
      }
    },
    compareContactObject() {
      const objectKeys = Object.keys(this.contactInfo);
      objectKeys.forEach(item => {
        if (item === "mainPhone" || item === "mainFax") {
          this.contactInformation[item].phone = this.sanitizePhoneString(this.contactInformation[item].phone);
          this.contactInformation[item].areaCode = this.sanitizePhoneString(this.contactInformation[item].areaCode);
          const resultOfPhone = isEqualWith(
            this.contactInformation[item],
            this.contactInfo[item],
            (value, otherValue) => {
              if (value.areaCode === undefined || otherValue.areaCode === undefined) return true;
              return (
                value.phone === otherValue.phone &&
                value.areaCode === otherValue.areaCode
              );
            }
          );
          if (!resultOfPhone) {
            this.hasChanges = true;
            return;
          }
        } else {
          if (
            !isEqual(this.contactInformation[item], this.contactInfo[item])
          ) {
            this.hasChanges = true;
            return;
          }
        }
      });
    },
  },
  created() {
    this.contactInformation = JSON.parse(JSON.stringify(this.contactInfo));
    this.isSelectedDefault = this.contactInfo.isDefault;
  },
};
