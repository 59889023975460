var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{directives:[{name:"async-form",rawName:"v-async-form"}],ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveAddress.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary"},[_vm._v(" "+_vm._s(_vm.getType)+" FACILITY ADDRESS INFORMATION ")]),_c('v-card-text',[_c('v-switch',{attrs:{"readonly":_vm.type == "edit" && _vm.isSelectedDefault,"label":"Default"},model:{value:(_vm.addressCopy.isDefault),callback:function ($$v) {_vm.$set(_vm.addressCopy, "isDefault", $$v)},expression:"addressCopy.isDefault"}}),_c('v-address-section',{ref:"facilityAddress",attrs:{"address":_vm.addressCopy.address,"customFields":[
          {
            type: 'type',
            label: 'Address Type',
            rules: [].concat( _vm.rules.required ),
          },
          {
            type: 'line1',
            label: 'Address Line 1',
            rules: [].concat( _vm.rules.required ),
          },
          {
            type: 'line2',
            label: 'Address Line 2',
          },
          {
            type: 'country',
            label: 'Country',
            rules: [].concat( _vm.rules.required ),
          },
          {
            type: 'city',
            label: 'City',
            rules: [].concat( _vm.rules.required ),
          },
          {
            type: 'state',
            label: 'State/Province',
            rules: [].concat( _vm.rules.required ),
          },
          {
            type: 'zipCode',
            label: 'Postal Code',
            rules: [].concat( _vm.rules.required ),
          } ]},on:{"update:address":function($event){return _vm.$set(_vm.addressCopy, "address", $event)}}}),_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"one-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 pa-1"},[_vm._v(" Main Phone No"),_c('sup',[_vm._v("*")])])],1)],1),_c('v-card-text',[_c('v-phone-section-2',{ref:"mainPhone",attrs:{"id":"address_phone_section","phones":[_vm.addressCopy.mainPhone],"label":"","singleSelection":true}})],1)],1),_c('v-card',{staticClass:"ma-2",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"one-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 pa-1"},[_vm._v(" Main Fax No"),_c('sup',[_vm._v("*")])])],1)],1),_c('v-card-text',[_c('v-phone-section-2',{ref:"mainFax",attrs:{"id":"address_fax_section","phones":[_vm.addressCopy.mainFax],"label":"","singleSelection":true}})],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pa-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"id":"address_cancel_btn","color":"primary","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"address_save_btn","color":"primary","disabled":_vm.isLoading,"type":"submit"}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }