import Actions from "modules/actions/sample-storage-laboratory-actions";

export default {
  name: "ContactInformations",
  components: {},
  props: {
    facility: {
      type: Object,
      required: true,
    },
  },
  computed: {
    __Facility: {
      get() {
        return this.facility;
      },
      set(value) {
        this.$emit("update:facility", value);
      },
    },
  },
  data() {
    return {
      actions: new Actions(),
      promptData: null,
      promptVisibility: false,
      promptMessage: "",
      isLoading: false,
      columns: [
        {
          text: "Contact Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Default",
          align: "left",
          sortable: false,
        },
        {
          text: "Contact Title",
          align: "left",
          sortable: false,
        },
        {
          text: "Contact Phone No",
          align: "left",
          sortable: false,
        },
        {
          text: "Contact Fax No",
          align: "left",
          sortable: false,
        },
        {
          text: "Contact Email Address",
          align: "left",
          sortable: false,
        },
        {
          text: "Creation Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getDefaultStatus(id) {
      const defaultId = this.facility.defaultContactInformation?.id;
      return defaultId == id ? "Yes" : "No";
    },
    editContact(id) {
      this.$emit("editContact", id);
    },
    async deleteContact(contactId) {
      try {
        await this.actions.updateSampleStorageLaboratory({
          storageLabId: {
            type: "UUID!",
            value: this.__Facility.id,
            unincludeToFields: true,
          },
          queries: {
            removeContactInformation: {
              contactInformationId: {
                type: "UUID!",
                value: contactId,
              },
            },
          },
        });

        this.showNotifyMessage({
          type: "success",
          message: "Contact Information deleted.",
        });
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message:
            "Problem has occurred while saving data.",
        });
      } finally {
        this.promptVisibility = false;
        this.getContactInformations();
      }
    },
    setPromptModalVisibility({ action, id, message }) {
      this.promptVisibility = true;
      this.promptMessage = message;
      this.promptData = {
        action,
        id,
      };
    },
    async setPromptAction(data) {
      const { action, id } = data;
      await this[action](id);
    },
    async getContactInformations() {
      try {
        this.isLoading = true;

        const result = await this.actions.getSampleStorageLaboratory({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.__Facility.id,
              },
            },
          },
          limitData: [
            this.buildSubQuery("defaultContactInformation", [
              "firstName",
              "middleName",
              "lastName",
              "nameTitle",
              this.buildSubQuery("mainPhone", [
                "phone",
                "areaCode",
              ]),
              this.buildSubQuery("mainFax", [
                "phone",
                "areaCode",
              ]),
              this.buildSubQuery("contactEmail", ["email"]),
            ]),
            this.buildSubQuery("contactInformations", [
              this.buildSubQuery("contactType", ["label"]),
              "firstName",
              "middleName",
              "lastName",
              "nameTitle",
              this.buildSubQuery("mainPhone", [
                "phone",
                "areaCode",
              ]),
              this.buildSubQuery("mainFax", [
                "phone",
                "areaCode",
              ]),
              this.buildSubQuery("contactEmail", ["email"]),
              "created",
            ]),
          ],
        });

        if (result) {
          this.__Facility.defaultContactInformation =
            result.defaultContactInformation;
          this.__Facility.contactInfo = result.contactInformations;

          this.__Facility.contactInfo.forEach((index) => {
            this.renameProperty(index, "email", "contactEmail");
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    renameProperty(obj, newKey, oldKey) {
      Object.defineProperty(
        obj,
        newKey,
        Object.getOwnPropertyDescriptor(obj, oldKey)
      );
      delete obj[oldKey];
    },
  },
};
